<template>
  <div class="product-quantity mt-30">
    <div v-if="item.max_qty > 0">
      <div class="cart-plus-minus">
        <div class="dec qtybutton" @click.prevent="minus">-</div>
        <input class="cart-plus-minus-box" type="text" v-model="qty" />
        <div class="inc qtybutton" @click.prevent="add">+</div>
      </div>
      <button @click.prevent="addToCart">加入購物車</button>
    </div>
    <div v-else>
      <p class="red">缺貨中。將儘速補貨，請您耐心等待</p>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  props: ["item"],
  data() {
    return {
      qty: this.item?.qty > 0 ? 1 : null,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    minus() {
      if (this.qty > 1) {
        this.qty -= 1;
      } else {
        this.qty = 1;
        swal({
          title: "數量不能小於0",
          icon: "warning",
          button: "確定",
        });
      }
    },
    add() {
      if (this.qty === null) {
        swal({
          title: "此商品已售完！",
          text: "",
          icon: "warning",
        });
      } else {
        if (this.item.max_qty > this.qty) {
          this.qty += 1;
        } else {
          swal({
            title: "已達購買上限",
            icon: "warning",
          });
        }
      }
    },
    addToCart() {
      if (this.qty === null) {
        swal({
          title: "此商品已售完！",
          icon: "warning",
        });
      } else {
        if (this.qty > this.item.max_qty) {
          swal({
            title: "數量選擇超過庫存上限",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", { ...this.cart.items, [this.item.key]: this.qty });
          swal({
            title: "已加入購物車",
            icon: "success",
          });
        }
      }
    },
  },
  watch: {
    cart() {
      this.qty = this.cart.items[this.item.key] || (this.item?.qty > 0 ? 1 : null);
    },
  },
};
</script>
