<template>
  <div v-show="items.length > 0" class="header-cart">
    <a href="#">
      <div class="cart-icon">
        <i class="ti-shopping-cart"></i>
      </div>
    </a>
    <div class="shopping-cart-content">
      <ul>
        <li v-for="(item, index) in items" :key="`item${index}`" class="single-shopping-cart">
          <div class="shopping-cart-img">
            <a :href="`/items/${item.key}`">
              <img :src="item.image.small.url" width="82" />
            </a>
          </div>
          <div class="shopping-cart-title">
            <h4>
              <a :href="`/items/${item.key}`">{{ item.title.substring(0, 10) }}</a>
            </h4>
            <h6>數量: {{ cart && cart.items && cart.items[item.key] }}</h6>
            <span>${{ item.price.toLocaleString() }}</span>
          </div>
          <div class="shopping-cart-delete">
            <a href="#" @click.prevent="remove(item.key)">
              <i class="ion ion-close"></i>
            </a>
          </div>
        </li>
      </ul>
      <div class="shopping-cart-total">
        <h4>
          購物金額 :<span class="shop-total">${{ totalAmount.toLocaleString() }}</span>
        </h4>
      </div>
      <div class="shopping-cart-btn">
        <a href="#!" @click.prevent="goToCart">結帳</a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["itemsInCart"],
  data() {
    return {
      items: this.itemsInCart.items,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    totalAmount() {
      let total = 0;
      if (this.cart.items) {
        this.items.forEach((item) => {
          total += item.price * this.cart.items[item.key];
        });
      }
      return total;
    },
  },
  methods: {
    remove(itemKey) {
      let tempCart = { ...this.cart.items };
      delete tempCart[itemKey];
      this.$store.dispatch("setCart", tempCart);
      this.items = this.items.filter((item) => item.key !== itemKey);
    },
    goToCart() {
      if (this.cart && Object.keys(this.cart.items).length > 0) {
        window.location.href = "/orders/new";
      } else {
        swal("提醒您", "無法結賬！購物車內沒有商品");
      }
    },
  },
  watch: {
    cart() {
      axios.post("/carts/get_items").then(({ data }) => {
        if (data.status === "success") {
          this.items = data.items_in_cart.items;
        }
      });
    },
  },
};
</script>
