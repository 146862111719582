import Vue from "vue/dist/vue.esm";
// 套件
import { ValidationProvider, ValidationObserver } from "vee-validate";
// store
import store from "./store";
// 其他元件
import MiniCart from "./components/mini_cart.vue";
import ModalAddBtn from "./components/modal_add_btn.vue";
import AddBtn from "./components/add_btn.vue";
import ItemAddBtn from "./components/item_add_btn.vue";
import CartTable from "./components/cart_table.vue";

// vue init
if (document.getElementById("vue-app")) {
  document.addEventListener("DOMContentLoaded", () => {
    new Vue({
      el: "#vue-app",
      store,
      created() {
        // 取回購物車車資料
        this.$store.dispatch("getCart");
      },
    });
  });
}

// 套件使用
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("mini-cart", MiniCart);
Vue.component("modal-add-btn", ModalAddBtn);
Vue.component("add-btn", AddBtn);
Vue.component("item-add-btn", ItemAddBtn);
Vue.component("cart-table", CartTable);

// 元件套用
// Vue.component('example', Example);
