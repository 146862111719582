<template>
  <a v-if="item.max_qty > 0" class="action-cart" href="#!" @click.prevent="addToCart">
    <i class="ion-ios-cart"></i>
  </a>
</template>
<script>
import swal from "sweetalert";
export default {
  props: ["item"],
  data() {
    return {
      qty: this.item?.qty > 0 ? 1 : null,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    addToCart() {
      if (this.qty === null) {
        swal({
          title: "此商品已售完！",
          icon: "warning",
        });
      } else {
        if (this.qty > this.item.max_qty) {
          swal({
            title: "數量選擇超過庫存上限",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", { ...this.cart.items, [this.item.key]: this.qty });
          swal({
            title: "已加入購物車",
            icon: "success",
          });
        }
      }
    },
  },
  watch: {
    cart() {
      this.qty = this.cart.items[this.item.key] || (this.item?.qty > 0 ? 1 : null);
    },
  },
};
</script>
