<template>
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>移除</th>
          <th>圖片</th>
          <th>商品名稱</th>
          <th>單價</th>
          <th>數量</th>
          <th>小計</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="`item${index}`">
          <td class="product-remove">
            <a href="#!" @click.prevent="remove(item.key)"><i class="fa fa-times"></i></a>
          </td>
          <td class="product-thumbnail">
            <a :href="`/items/${item.key}`"><img :src="`${item.image.small.url}`" /></a>
          </td>
          <td class="product-name">
            <a :href="`/items/${item.key}`">{{ item.title }}</a>
            <div class="cart-item-no">{{ item.item_no }}</div>
          </td>
          <td class="product-price-cart">
            <span class="amount">${{ item.price.toLocaleString() }}</span>
          </td>
          <td class="product-quantity">
            <div class="posr">
              <div class="posa">
                <div class="dec qtybutton" @click.prevent="minus(item.key)">-</div>
                <input
                  class="cart-plus-minus-box"
                  type="text"
                  :value="cart.items && cart.items[item.key]"
                  readonly
                />
                <div class="inc qtybutton" @click.prevent="add(item.key)">+</div>
              </div>
            </div>
          </td>
          <td class="product-subtotal">
            ${{ cart.items && (item.price * cart.items[item.key]).toLocaleString() }}
          </td>
        </tr>

        <!-- 運費 -->
        <tr>
          <td v-if="!isMobile" colspan="5" class="text-end">
            運費 <span>(滿{{ freeShipmentCondition }}免運)</span>
          </td>
          <td v-if="!isMobile">
            <span class="amount">${{ freight }}</span>
          </td>
        </tr>
        <!-- 應付 -->
        <tr>
          <td v-if="!isMobile" colspan="5" class="text-end">應付金額:</td>
          <td v-if="!isMobile">
            <span class="amount">${{ calculateTotal && calculateTotal.toLocaleString() }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-if="isMobile" class="table table-hover">
      <tbody>
        <tr>
          <td class="text-center">
            運費 <span>(滿{{ freeShipmentCondition }}免運):&nbsp;</span>
            <span class="amount">${{ freight }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            應付金額:
            <span class="amount">${{ calculateTotal && calculateTotal.toLocaleString() }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["itemsInCart", "freeShipmentCondition", "isMobile"],
  data() {
    return {
      items: this.itemsInCart.items,
    };
  },
  computed: {
    freight() {
      return this.$store.state.freight;
    },
    cart() {
      return this.$store.state.cart;
    },
    totalAmount() {
      let total = 0;
      this.items.forEach((item) => {
        if (this.cart.items) {
          total += item.price * this.cart.items[item.key];
        }
      });
      return total;
    },
    calculateTotal() {
      let total = 0;
      total = this.totalAmount;
      if (total < 0) {
        total = 0;
      }
      return total + this.freight;
    },
  },
  methods: {
    add(itemKey) {
      // 取得當前的數量
      let qty = this.cart.items[itemKey];
      let item = this.items.find((item) => item.key === itemKey);
      qty += 1;
      if (qty > item.max_qty) {
        swal({
          title: "超過上限",
          text: "您的選擇已超過庫存上限",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", { ...this.cart.items, [itemKey]: qty });
      }
    },
    minus(itemKey) {
      let qty = this.cart.items[itemKey];
      qty -= 1;
      if (qty <= 0) {
        swal({
          title: "低於下限",
          text: "數量請勿低於0。如要刪除此項商品，請點擊刪除鈕。",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", { ...this.cart.items, [itemKey]: qty });
      }
    },
    remove(itemKey) {
      let tempCart = { ...this.cart.items };
      delete tempCart[itemKey];
      this.$store.dispatch("setCart", tempCart);
      this.items = this.items.filter((item) => item.key !== itemKey);
      if (this.items.length === 0) {
        swal({
          title: "您的購物車是空的",
          text: "請先選擇商品",
          icon: "warning",
        }).then(() => {
          window.location.href = "/items";
        });
      }
    },
  },
  watch: {
    cart() {
      axios.post("/carts/get_items").then(({ data }) => {
        if (data.status === "success") {
          this.items = data.items_in_cart.items;
        }
      });
    },
  },
};
</script>
