import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import axios from "axios";

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common["X-CSRF-Token"] = csrf;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cart: {},
    freight: 0,
  },
  mutations: {
    setCart: (state, payload) => {
      state.cart = payload;
      return state;
    },
    setFreight: (state, payload) => {
      state.freight = payload;
      return state;
    },
  },
  actions: {
    setCart: (context, payload) => {
      axios.post("/carts/set", { cart: payload }).then(({ data }) => {
        if (data.status == "success") {
          context.commit("setCart", JSON.parse(data.cart));
          context.commit("setFreight", data.freight);
        }
      });
    },
    getCart: (context) => {
      axios.get("/carts").then(({ data }) => {
        // console.log(data)
        if (data.status == "success") {
          context.commit("setCart", data.cart);
          context.commit("setFreight", data.freight);
        }
      });
    },
  },
});

export default store;
